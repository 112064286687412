import Vue from 'vue';
import Vant from 'vant';
import moment from 'moment';
import App from './App.vue';
import ElementUI from 'element-ui';
import VueLuckyCanvas from '@lucky-canvas/vue';
import store from './store'
import router from './routes';
import utils from './utils';
import api from './utils/api';
import http from './utils/http';
import cache from './utils/cache';
import config from './utils/config';
import 'animate.css';
import 'vant/lib/index.css';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import '@/common/styles/element-variables.scss';
import '@/common/styles/index.scss';
import '@/common/styles/index.css';

Vue.prototype.$api = api
Vue.prototype.$http = http
Vue.prototype.$utils = utils
Vue.prototype.$cache = cache
Vue.prototype.$config = config
Vue.prototype.$moment = moment
Vue.config.productionTip = false
Vue.use(Vant)
Vue.use(ElementUI)
Vue.use(VueLuckyCanvas)

new Vue({
	store,
	router,
	render: h => h(App),
}).$mount('#app')
